<template>
  <div style="display: flex; flex-direction: column; min-height: 100vh">
    <div style="align-self: center">
      <h2 class="mt-8" style="font-weight: bold; color: var(--dark)">
        {{ $t(`alpha_register_4_header`) }}
      </h2>
      <p class="register-subheader my-3 mb-12">
        {{ $t(`alpha_register_4_subheader`) }}
      </p>
      <p style="font-size: 18px; font-weight: bold; color: var(--dark)">
        {{ $t("step_count", { current: 1, last: 6 }) }}
      </p>
      <v-progress-linear
        class="pa-0 mb-0"
        color="primary"
        style="width: 1135px"
        rounded
        height="6"
        :value="(1 * 100) / 6"
      ></v-progress-linear>
    </div>
    <v-form
      v-model="valid"
      ref="form"
      style="display: flex; justify-content: center"
    >
      <v-card
        class="info-card py-11 px-6 ma-0"
        style="border-top-right-radius: 0px; border-bottom-right-radius: 0"
        width="850"
        color="white"
      >
        <v-row>
          <v-col cols="6" class="py-0">
            <span class="field-header" style="color: var(--dark)">{{
              $t("nationality")
            }}</span>
            <v-autocomplete
              color="dark"
              class="mb-4 my-combobox-style"
              dense
              filled
              rounded
              :rules="[required]"
              :items="countries"
              :disabled="User.JuridicalType == JuridicalTypeEnum.LegalPerson"
              item-text="name"
              return-object
              @change="(e) => nationality_code_changed(e)"
              v-model="nationalitySelected"
              style="z-index: 1000"
              data-test="Alpha:InvestorInfo:Nationality"
            >
              <template slot="selection" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 20px; height: auto"
                  alt=""
                />
                <span class="mx-2">{{ data.item.name }}</span>
              </template>
              <template slot="item" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 20px; height: auto"
                  alt=""
                />
                <span class="mx-2">{{ data.item.name }}</span>
              </template></v-autocomplete
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="py-0">
            <span style="color: var(--dark)">{{ $t("document_type") }}</span>
            <v-radio-group
              v-model="User.DocumentType"
              style="margin-top: 4px"
              row
            >
              <v-radio
                style="display: flex; align-itens: center"
                v-for="docType in docsType"
                :key="docType.value"
                :value="docType.value"
                :data-test="`Alpha:InvestorInfo:documentType:${docType.text}`"
              >
                <template v-slot:label>
                  <span class="document-type-text">{{
                    translateDocsType(docType)
                  }}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="py-0">
            <label style="color: var(--dark)">{{
              $t("document_number")
            }}</label>
            <v-text-field
              data-test="Alpha:InvestorInfo:DocumentNumber"
              dense
              rounded
              :disabled="
                User.DocumentType == documentTypeEnum.select ? true : false
              "
              :placeholder="
                User.DocumentType == documentTypeEnum.cpf
                  ? '000.000.000-00'
                  : ''
              "
              :class="{
                'invalid-field': !User.SocialNumber || socialNumberExists,
              }"
              class="my-input-style"
              v-model="User.SocialNumber"
              type="tel"
              filled
              :rules="[
                required,
                (v) =>
                  (User.DocumentType == documentTypeEnum.cpf
                    ? vs.social_number_valid(User.SocialNumber)
                    : User.SocialNumber.length >= 7) || $t('format_invalid'),
              ]"
              v-mask="
                User.DocumentType == documentTypeEnum.cpf
                  ? '###.###.###-##'
                  : ''
              "
              @change="check_social_number()"
            />
            <p
              style="color: var(--dark); font-size: 11px"
              class="ma-0"
              v-if="
                validatingSocialNumber &&
                User.DocumentType == documentTypeEnum.cpf
              "
            >
              {{ $t("validating") }}
            </p>
            <p
              style="color: red; font-size: 11px"
              class="ma-0"
              v-else-if="
                socialNumberFormatInvalid &&
                User.DocumentType == documentTypeEnum.cpf
              "
            >
              {{ $t("format_invalid") }}
            </p>
            <p
              style="color: red; font-size: 11px"
              class="ma-0"
              v-if="socialNumberExists"
            >
              {{
                User.DocumentType == documentTypeEnum.cpf
                  ? $tc("social_number_exists", 1)
                  : $tc("social_number_exists", 2)
              }}
            </p>
          </v-col>
          <v-col cols="6" class="py-0">
            <p class="mb-0" style="color: var(--dark)">{{ $t("name") }}</p>
            <v-text-field
              data-test="Alpha:InvestorInfo:Name"
              v-model="User.Name"
              filled
              dense
              rounded
              :rules="[(v) => (!!v && User.Name != null) || required()]"
              class="mb-4 my-input-style"
              :placeholder="$t('type_your_name')"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <p class="mb-0" style="color: var(--dark)">{{ $t("last_name") }}</p>
            <v-text-field
              data-test="Alpha:InvestorInfo:LastName"
              v-model="User.LastName"
              filled
              :rules="[(v) => (!!v && User.LastName != null) || required()]"
              dense
              rounded
              class="mb-4 my-input-style"
              :placeholder="$t('type_your_lastname')"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- line-3 -->
        <v-row style="place-content: space-between">
          <v-col cols="6" class="my-0 py-0 pr-3">
            <p class="mb-0" style="color: var(--dark)">{{ $t("email") }}</p>
            <v-text-field
              :placeholder="$t('email_placeholder')"
              v-model="User.Email"
              filled
              @keydown.space.prevent
              dense
              rounded
              data-test="Alpha:InvestorInfo:Email"
              :hide-details="validatingEmail"
              @change="check_email"
              :rules="[
                required,
                email_valid,
                !emailFormatInvalid || $t('format_invalid'),
                !emailExists || $t('user_exists'),
              ]"
              :class="validatingEmail ? 'mb-0' : 'mb-4'"
              class="my-input-style"
            ></v-text-field>
            <p v-if="validatingEmail">{{ $t("validating") }}</p>
          </v-col>
          <v-col cols="2" class="my-0 py-0 pr-3">
            <p class="mb-0" style="color: var(--dark)">
              {{ $t("country_code") }}
            </p>
            <v-autocomplete
              class="my-combobox-style"
              data-test="Alpha:InvestorInfo:CountryCode"
              color="dark"
              dense
              filled
              rounded
              :items="countries"
              item-text="name"
              :rules="[(v) => (!!v && countryCode != null) || required()]"
              return-object
              @change="country_code_changed"
              v-model="countryCode"
            >
              <template slot="selection" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 20px; height: auto"
                  alt=""
                />
                <span class="mx-2">{{ data.item.callingCodes[0] }}</span>
              </template>
              <template slot="item" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 20px; height: auto"
                  alt=""
                />
                <span class="mx-2">{{ data.item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" class="py-0">
            <p class="mb-0" style="color: var(--dark)">{{ $t("celphone") }}</p>
            <v-text-field
              data-test="Alpha:InvestorInfo:Celphone"
              dense
              rounded
              style="border-radius: 4px"
              @keydown.space.prevent
              :class="{ 'invalid-field': !User.CelPhone }"
              class="my-input-style"
              v-model="User.CelPhone"
              filled
              :rules="[
                (v) =>
                  (!!v &&
                    ((countryCode &&
                      countryCode.callingCodes[0] != '55' &&
                      User.CelPhone.length >= 8) ||
                      (countryCode &&
                        countryCode.callingCodes[0] == '55' &&
                        User.CelPhone.length >= 14))) ||
                  required(),
              ]"
              v-mask="
                countryCode && countryCode.callingCodes[0] == '55'
                  ? '(##) #####-####'
                  : ''
              "
              type="tel"
            />
          </v-col>
        </v-row>

        <!-- line-4 -->
        <v-row style="place-content: space-between">
          <v-col cols="6" class="py-0">
            <p class="mb-0" style="color: var(--dark)">
              {{
                $t(
                  User.JuridicalType == JuridicalTypeEnum.NaturalPerson
                    ? $t("birth_date")
                    : $t("establishment_date")
                )
              }}
            </p>
            <v-text-field
              data-test="Alpha:InvestorInfo:BirthDate"
              filled
              rounded
              dense
              :class="{
                'invalid-field':
                  !User.Birth || !isDateValid || !isDateAboveLegal,
              }"
              v-model="date"
              :rules="[(v) => (!!v && v.length == 10) || required()]"
              placeholder="DD/MM/YYYY"
              v-mask="['##/##/####']"
              @blur="
                User.Birth = parseDate(date);
                check_date();
              "
              type="tel"
              class="my-input-style"
            />
            <p
              style="color: red; font-size: 11px"
              class="mb-4 mt-n4 ml-2"
              v-if="date == '' && fieldsRequired == true"
            >
              {{ $t("required") }}
            </p>
            <p
              style="color: red; font-size: 11px"
              class="mb-4 mt-n3 ml-3"
              v-if="!isDateValid"
            >
              {{ $t("valid_date") }}
            </p>
            <p
              style="color: red; font-size: 11px"
              class="mb-4 mt-n4 ml-2"
              v-else-if="!isDateAboveLegal"
            >
              {{ $t("min_18") }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="py-0 pr-3">
            <span style="color: var(--dark)">{{ $t("password") }}</span>
            <v-text-field
              data-test="Alpha:InvestorInfo:Password"
              @keyup="verifyRequirements"
              @keydown.space.prevent
              dense
              rounded
              ref="password"
              :rules="[
                (v) => (!!v && verifyAllPasswordRequirements) || required(),
              ]"
              v-model="User.UserPassword"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              small
              @click:append="show1 = !show1"
              filled
              class="my-input-style"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0"
            ><span style="color: var(--dark)">{{
              $t("password_confirmation")
            }}</span>

            <v-text-field
              data-test="Alpha:InvestorInfo:PasswordConfirmation"
              @keydown.space.prevent
              v-model="passwordConfirmation"
              :rules="[required, ...passwordConfirmationRule]"
              dense
              rounded
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              filled
              class="my-input-style"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <ValidateStrengthPassword :userPassword="User.UserPassword" />
          </v-col>
        </v-row>

        <PasswordChecklist
          :password="User.UserPassword"
          ref="passwordChecklist"
        />
        <DxaButton
          data-test="Alpha:InvestorInfo:Submit"
          @function="checkForm"
          :title="$t('continue')"
          class="mt-10 mb-8"
        ></DxaButton>
      </v-card>
      <v-card
        class="ma-0 pa-5"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: var(--dark);
          border-radius: 0px 12px 12px 0px;
          box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
        "
      >
        <div style="display: flex; justify-content: end">
          <img
            style="
              margin-right: 15px;
              margin-top: 15px;
              max-width: 50px;
              opacity: 0;
            "
            src="../../../../../../../../assets/dxa-x.png"
            alt=""
          />
        </div>
        <div>
          <img
            style="
              max-height: 256px;

              transform: scaleX(-1);
            "
            src="../../../../../../../../assets/dxa-background-welcome-page3(1).png"
            alt=""
          />
        </div>
      </v-card>
    </v-form>
    <!--Dialog -->

    <v-dialog
      style="z-index: 100000"
      persistent
      max-width="450"
      v-model="socialNumberDialog"
      ><v-card
        class="dialog-bg"
        style="border-radius: 10px; background-color: var(--white) !important"
      >
        <v-row justify="end" align="center" class="pr-6">
          <v-icon @click="socialNumberDialog = false" color="black"
            >mdi-close</v-icon
          >
        </v-row>
        <div class="icon-alert">
          <img src="@/assets/alert-icon.svg" alt="alert" />
        </div>
        <h2 class="pt-3 px-4 pb-3 text-center" style="color: var(--dark)">
          {{ $t("social_number_already_exists") }}
        </h2>
        <p
          class="px-12 text-center"
          style="
            color: var(--dark);
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            letter-spacing: 0.25px;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Source Sans Pro';
          "
        >
          {{ $t("if_social_number_is_yours") }}
        </p>
        <div class="alert-create-box">
          <v-btn
            outlined
            style="
              border-style: groove;
              border-color: var(--primary);
              background-color: var(--white);
              color: var(--primary);
              font-size: 16px;
              border-radius: 8px;
            "
            height="35"
            width="124"
            @click="socialNumberDialog = false"
            class="mb-5"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            outlined
            style="
              border-radius: 8px;
              background-color: var(--primary);
              color: var(--white);
              border: none;
              font-size: 16px;
            "
            height="35"
            width="124"
            @click="goToLogin"
            class="mb-5"
            >{{ $t("login_txt") }}</v-btn
          >
        </div>
      </v-card></v-dialog
    >
  </div>
</template>

<style scoped>
.document-type-text {
  font-size: 16px !important;
  font-family: "Source Sans Pro";
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.alert-create-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.icon-alert {
  display: grid;
  place-items: center;
}

.dialog-bg {
  padding-top: 20px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationServices from "../../../../../../../../services/ValidationService";
import countriesJson from "@/utils/countries.json";
import moment from "moment";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
import PasswordChecklist from "@/components/dxa/PasswordChecklist/PasswordChecklist.vue";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import mixpanel from "mixpanel-browser";
import ValidateStrengthPassword from "@/components/dxa/Password/ValidateStrengthPassword";

export default {
  components: { DxaButton, PasswordChecklist, ValidateStrengthPassword },
  props: {
    User: Object,
  },
  data() {
    return {
      documentTypeEnum: DocumentTypeEnum,
      valid: true,
      validatingEmail: false,
      socialNumberDialog: false,
      apiService: new ApiService(),
      gs: new GeneralServices(),
      vs: new ValidationServices(),
      show1: false,
      countries: countriesJson,
      documentNumber: "",
      emailExists: false,
      documentType: 0,
      docsType: [{ text: "CPF", value: 1 }],
      date: null,
      isDateValid: true,
      isDateAboveLegal: true,
      fieldsRequired: false,
      socialNumberExists: false,
      JuridicalTypeEnum,
    };
  },
  created() {
    this.checkInvestorFormData();

    if (this.User.Nationality == null) {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == "Brazil"
      )[0];
      this.User.Nationality = "Brazil";
    } else {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == this.User.Nationality
      )[0];
    }
    if (this.countryCode == null || this.countryCode == "") {
      this.countryCode = this.countries.filter((x) => x.name == "Brazil")[0];
    }
    if (this.User.Birth) {
      var birth = moment(this.User.Birth);
      if (birth.format("YYYY") != "0001") {
        this.date = birth.format("DD/MM/YYYY");
      }
    }
    if (this.User.DocumentType == this.documentTypeEnum.select) {
      this.User.DocumentType = this.documentTypeEnum.cpf;
    }

    if (this.User.Nationality) {
      this.$emit("getPortfolioOptions", this.User.Nationality);
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.User.UserPassword == this.passwordConfirmation ||
        this.$t("pswds_dnt_match");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    verifyAllPasswordRequirements() {
      return this.$refs.passwordChecklist.checkAllRequirements();
    },
    min18Years() {
      return () =>
        (this.date != null &&
          moment().diff(
            moment([
              parseInt(this.date.split("/")[2]),
              parseInt(this.date.split("/")[1]) - 1,
              parseInt(this.date.split("/")[0]),
            ]),
            "years"
          ) >= 18) ||
        this.$t("min_18");
    },
    valid_date() {
      return () =>
        (this.date &&
          parseInt(this.date.split("/")[2]) > 1600 &&
          moment([
            parseInt(this.date.split("/")[2]),
            parseInt(this.date.split("/")[1]) - 1,
            parseInt(this.date.split("/")[0]),
          ]).isValid()) ||
        this.$t("valid_date");
    },
    email_valid() {
      return (v) =>
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) || this.$t("format_invalid");
    },
  },
  watch: {
    nationalitySelected() {
      if (this.nationalitySelected) {
        this.$emit("getPortfolioOptions", this.User.Nationality);
      }
    },
  },
  methods: {
    checkInvestorFormData() {
      const investorFormDataLocalStorage =
        localStorage.getItem("investorFormData");
      if (investorFormDataLocalStorage) {
        const investorFormData = JSON.parse(investorFormDataLocalStorage);

        if (investorFormData.form_data) {
          const decodedData = Buffer.from(
            investorFormData["form_data"],
            "base64"
          ).toString();
          const parsedDecodedData = JSON.parse(decodedData);
          const parsedName = parsedDecodedData.name.split(" ");
          if (parsedName.length > 1) {
            this.User.LastName = parsedName[1];
          }
          this.User.Name = parsedName[0];
          this.User.Email = parsedDecodedData.email;
          this.User.CelPhone = this.parsePhoneNumber(parsedDecodedData.phone);
          this.date = parsedDecodedData.birthDate;
          this.User.Birth = this.parseDate(parsedDecodedData.birthDate);
          this.User.SocialNumber = parsedDecodedData.documentNumber;
          this.User.Nationality = parsedDecodedData.nationality;
        }
      }
    },
    parsePhoneNumber(number) {
      return number.substring(3);
    },
    goToLogin() {
      this.$router.push("/auth");
    },
    fieldBecomeEmpty() {
      if (this.User.DocumentType == this.documentTypeEnum.select) {
        this.User.SocialNumber = "";
        this.User.DocumentNumber = "";
      }
      if (this.User.DocumentType == this.documentTypeEnum.cpf) {
        this.User.DocumentNumber = "";
      }
      if (this.User.DocumentType == this.documentTypeEnum.passport) {
        this.User.SocialNumber = "";
      }
    },

    verifyRequirements() {
      this.$refs.passwordChecklist.verifyRequirements();
      this.$refs.password.validate();
    },
    check_password_number(value) {
      return /[0-9]/.test(value);
    },
    check_password_lower(value) {
      return /[a-z]/.test(value);
    },
    check_password_upper(value) {
      return /[A-Z]/.test(value);
    },
    translateDocsType(item) {
      return this.$t(item.text);
    },
    async check_email() {
      if (
        !this.vs.email_valid(this.User.Email) ||
        !this.vs.accent_valid(this.User.Email)
      ) {
        this.emailFormatInvalid = true;
        return;
      } else {
        this.emailFormatInvalid = false;
      }
      this.validatingEmail = true;
      this.emailExists = await this.vs.check_if_email_exists(
        this.User.Id,
        this.User.Email,
        true
      );
      this.validatingEmail = false;
    },
    check_date() {
      if (this.valid_date() != true) {
        this.isDateValid = false;
        return;
      }
      this.isDateValid = true;
      if (
        this.User.JuridicalType == this.JuridicalTypeEnum.NaturalPerson &&
        this.min18Years() != true
      ) {
        this.isDateAboveLegal = false;
        return;
      }
      this.isDateAboveLegal = true;
    },
    parseDate(date) {
      if (!date) return null;
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    async check_social_number() {
      let onlyDigits = true;

      // this.socialNumberFormatInvalid = false;
      // if (!this.social_number_valid) {
      //   this.socialNumberFormatInvalid = true;
      //   return;
      // }
      if (this.User.DocumentType == this.documentTypeEnum.cpf) {
        if (this.User.SocialNumber.length < 14) {
          return;
        }
      } else if (this.User.DocumentType == this.documentTypeEnum.passport) {
        // quando é passaporte permitimos colocar letras.
        onlyDigits = false;

        if (this.User.SocialNumber.length < 7) {
          return;
        }
      }
      this.validatingSocialNumber = true;
      this.socialNumberExists = await this.vs.check_if_social_number_exists(
        this.User.Id,
        this.User.SocialNumber,
        "user",
        onlyDigits
      );
      this.validatingSocialNumber = false;
      if (this.socialNumberExists) {
        this.socialNumberDialog = true;
      }
    },
    verifyUserNationality(e) {
      if (e.alpha3Code !== "BRA") {
        this.docsType = [
          { text: "CPF", value: 1 },
          { text: "passport", value: 2 },
        ];
      } else {
        this.docsType = [{ text: "CPF", value: 1 }];
      }
    },
    nationality_code_changed(e) {
      this.verifyUserNationality(e);
      if (this.nationalitySelected.name) {
        this.User.Nationality = this.nationalitySelected.name;
        this.$emit("getPortfolioOptions", this.nationalitySelected.name);
      }
    },
    country_code_changed(e) {
      if (this.User.CelPhone) {
        this.User.CelPhone = "";
      }
    },
    checkForm() {
      var result = this.$refs.form.validate();
      if (this.validatingSocialNumber || this.validatingEmail) {
        return;
      }

      // só chama o next_step se a senha for válida
      if (!this.$refs.passwordChecklist.checkFullPasswordIsValid()) return;

      if (
        result &&
        !this.socialNumberExists &&
        !this.emailExists &&
        !this.emailFormatInvalid &&
        !this.socialNumberDialog &&
        this.isDateAboveLegal &&
        this.isDateValid
      ) {
        this.User.CountryCode = this.countryCode;
        this.$gtag.event("investor_info", {
          event_category: "investor_register",
          event_label: this.User.Nationality,
          value: 0,
        });

        // DISPARANDO EVENTO DO CADASTRO DO INVESTIDOR
        mixpanel.track("InvestorRegister_Start", {
          partner_id: this.User.PartnerId,
          banker_id: this.User.BankerId,
          user_name: this.User.Name,
          user_email: this.User.Email,
          user_type: "investor",
          nationality: this.User.Nationality,
          user_document_id: this.User.SocialNumber,
          phone: this.User.CelPhone,
        });

        this.$emit("next_step");
      }
    },
  },
};
</script>
<style scoped>
.requirements {
  font-size: 14px;
  font-family: "Source Sans Pro";
  font-weight: 300;
  color: #9c9c9c;
}

.info-card {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 12px;
}

.v-text-field--rounded {
  border-radius: 4px;
}

.my-input-style >>> .v-text-field__slot input {
  color: var(--dark);
}

.my-combobox-style >>> .v-select__slot span {
  color: var(--dark);
}

.register-subheader {
  font-size: 20px;
  color: #9c9c9c;
  font-weight: 300;
  font-family: "Source Sans 3 Light";
}
</style>
<style>
.app_bar {
  z-index: 2;
}
.btn_lang {
  margin-top: 10px !important;
}
.back_btn {
  z-index: 3;
}
</style>
